<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Task progress</v-card-title>

      <v-alert v-if="task && task.completed" type="success" prominent tile>
        <h3>Ready!</h3>
        <p class="font-weight-bold mb-0">{{ task.msg }}</p>
        <p>Task completed {{task.updated | ago}}.</p>
        <p v-if="task.type === 'RESULT_IMPORT'">
          <v-btn :to="{ name: 'race', params: {id: task.event_id, raceId: task.race_id}}" target="_blank">View Results</v-btn>
          <v-btn text :to="{ name: 'eventmanagerRace', params: {id: task.event_id, raceId: task.race_id}}">Back to event manager</v-btn>
        </p>
        <v-btn v-else-if="task.result" :href="task.result">View Result</v-btn>
        <v-btn v-else-if="task.race_id" :to="{ name: 'eventmanagerRace', params: {id: task.event_id, raceId: task.race_id}}">View Leaderboard</v-btn>
      </v-alert>
      <v-card-text v-if="task && !task.completed">      
        <v-progress-linear v-if="task.progress" v-model="task.progress" class="mb-4" />
        <p class="font-weight-bold mb-0">{{ task.msg }}</p>
        <p>Last updated: {{task.updated | ago}}</p>

        <p class="text-muted">Please keep this tab open, we'll automatically refresh the task progress.</p>
      </v-card-text>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import Header from './_Header.vue'

export default {
  name: "Task",
  components: {
    Header,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      task: null,
    };
  },
  async mounted() {
    await this.getData();
    
    EventBus.$on('login-state-change', async user => {
      await this.getData();
    });
  },
  methods: {
    async getEvent(id, taskId) {
      this.task = (await eventManagerService.getTask(id, taskId)).data;
      console.log('model', this.task);
      //this.task.completed = true;

      if (this.task && !(this.task.completed || this.task.has_error)) {
        window.setTimeout(this.getData, 5000);
      }
    },

    async getData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.taskId);
      }
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: `Task details`, disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

